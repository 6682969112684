.RecipeToggle {
  &-separator {
    border-left: 1px #18181780 solid;
    height: 20px;
    margin: 0 10px 2px;
  }

  &-toggle {
    color: #18181780;
    margin-bottom: 2px;
    cursor: pointer;
  }
}
