.Header {
  align-items: center;
  color: #2fd3ae;
  display: flex;
  margin-top: 16px;
  text-transform: lowercase;

  &-text {
    flex: 1 0;
    font-size: 1.5rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }

    &:first-child {
      text-align: right;
    }
  }

  &-logo {
    width: 50px;
    height: 50px;
    margin: 0 10px;
  }

  &-about {
    color: #23B0FF;
    margin-right: 10px;
  }
}
