.Ingredient {
    display: flex;
    align-items: center;

  &-markerContainer {
    display: flex;
    font-size: 0.35em;
    justify-content: center;
    padding-right: 5px;
    width: 13px;
  }
}
