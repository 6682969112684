.Steps {
  &-headingContainer {
    display: flex;
    align-items: center;
  }

  &-heading {
    color: #fd009c;
    margin-bottom: 5px;
    margin-top: 0;
  }

  &-list {
    font-family: "Raleway", sans-serif;
    margin-top: 0;

    a {
      color: inherit;
    }
  }

  &-note {
    color: #23b0ff;
    list-style-type: none;
    padding: 0 20px;
  }
}
