.About {
  font-family: "Raleway", sans-serif;

  &-heading {
    font-family: "Lato", sans-serif;
    color: #2fd3ae;
    margin-bottom: 5px;
  }

  &--emphasise {
    color: #FD009C;
    font-weight: bold;
  }

  &--deemphasise {
    color: #171718;
    opacity: .7;
  }

  &--link {
    color: #171718;
  }
}
