.Recipe {
  margin: 0 10px;

  &-heading {
    color: #2fd3ae;
    margin-bottom: 5px;
  }

  &-serves {
    margin-top: 0;
    opacity: 0.5;
  }
}
