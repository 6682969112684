.RecipeList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  &-item {
    flex-grow: 1;
    margin: 2px;

    @media screen and (min-width: 768px) {
      margin: 4px;
    }
  }

  &-itemLink {
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    text-decoration: none;

    &--color0 {
      background-color: #2fd3ae;
    }

    &--color1 {
      background-color: #fd009c;
    }

    &--color2 {
      background-color: #fbb020;
    }

    &--color3 {
      background-color: #23b0ff;
    }
  }

  &-itemContent {
    margin: 10px;
  }

  &-title {
    font-size: 1.4em;
    margin: 0;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &-type {
    margin: 0;
    text-transform: lowercase;
  }
}
