.Ingredients {
  &-headingContainer {
    display: flex;
    align-items: center;
  }

  &-headingSeparator {
    border-left: 1px #18181780 solid;
    height: 20px;
    margin: 0 10px 2px;
  }

  &-heading {
    color: #fd009c;
    margin-bottom: 5px;
    margin-top: 0;
  }

  &-optionalsToggle {
    color: #18181780;
    margin-bottom: 2px;
    cursor: pointer;
  }

  &-list {
    font-family: "Raleway", sans-serif;
    list-style-type: none;
    margin-top: 0;
    padding-left: 22px;

    a {
      color: inherit;
    }
  }
}
